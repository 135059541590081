<template>
  <div class="user page">
    <Nav></Nav>
    <div class="wrap" style="padding-top: 10px; min-height: 700px">
      <a-layout>
        <a-layout-sider>
          <Menu></Menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-row type="flex" justify="center" align="middle" class="top">
              <a-col :span="6">
                <icon-font type="icon-coupons" /> 礼券：{{ user.couponCount }}张
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content>
            <!-- <img
              src="../../assets/bg.8425c48f.jpg"
              width="100%"
              height="300px"
            /> -->
            <div class="month-detail">
              <div class="coupon one">
                <div class="month-detail-flex">
                  <div
                    class="month-detail-flex-item"
                    v-for="(item, index) in coupons"
                    :key="index"
                  >
                    <div class="title">{{ item.name }}</div>
                    <div class="money">
                      <div class="money-num">{{ item.amount }}</div>
                      元
                    </div>
                    <div class="type">{{ item.typeName }}</div>
                    <div class="btn">
                      <div class="btn-text" @click="pay(item)">
                        立即领取
                        <div class="btn-text-icon"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
    <Foot></Foot>
  </div>
</template>
  
  <script>
import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";
import Menu from "../user/components/menu";
import { Icon } from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2747721_gimu9jlw02u.js",
});
export default {
  components: {
    Nav,
    Foot,
    Menu,
    IconFont,
  },
  data() {
    return {
      user: {},
      coupons: [],
    };
  },
  computed: {},
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
  },
  mounted() {
    this.user = this.storage.getItem("user");
    if (!this.user) {
      this.getUserInfo();
    }
    this.api
      .getcoupons({})
      .then((res) => {
        this.coupons = res;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.storage.setItem("user", res);
          this.user = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    pay(item) {
      this.api
        .getcoupon({
          id: item.id,
        })
        .then((res) => {
          if (res.errCode == 0) {
            return this.$message.success("领取成功", 2);
          } else {
            return this.$message.error(res.errMsg, 2);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handle(num) {
      console.log(num);
    },
  },
};
</script>
  
  
  <style lang="less">
@import "~ant-design-vue/dist/antd.less";

.page {
  background: #f5f5f5;
  height: auto;
}

.wrap {
  height: 100%;

  // padding-bottom: 20px;
}

.ant-layout {
  // height: 100%;
}

.ant-layout-sider,
.ant-layout-content,
.ant-layout-header,
.ant-layout-footer {
  background: #ffffff !important;
  padding: 10px;
}

.ant-layout-sider {
  margin-right: 10px;
  text-align: center;
}

.ant-layout-content {
  margin-top: 10px;
}

.ant-layout-header {
  height: auto;

  .top {
    .ant-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .anticon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.user {
  .user-name {
    font-size: 14px;
    margin-top: 10px;
  }

  .other-info {
    text-align: left;
    margin-left: 30px;

    span {
      color: @text-color-secondary;
    }
  }
}

.menu {
  text-align: left;
  padding-left: 30px;
  line-height: 28px;
  margin-top: 30px;
  border-top: 1px solid #e2e2e2;

  dl {
    padding-top: 10px;

    dt {
      font-size: 16px;
      line-height: 28px;
    }

    dd {
      color: @text-color-secondary;
    }
  }
}

div.content-wrap {
  padding: 15px;
}

.month-detail {
  padding: 20px;

  .coupon {
    height: auto;
    background: #c60000;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 4px;

    .month-detail-flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .month-detail-flex-item {
        width: 45%;
        height: 275px;
        background: url(../../assets/card-bg.png) center center no-repeat;
        background-size: 100% 100%;
        margin-bottom: 4px;
        position: relative;
        text-align: center;
        white-space: nowrap;
        margin-bottom: 20px;

        .title {
          font-size: 20px;
          color: #fffef8;
          background: linear-gradient(to right, #fb6e4c, #f85450, #f12158);
          position: absolute;
          top: 2px;
          left: 50%;
          transform: translateX(-50%);
          padding: 8px;
          border-radius: 0 0 12px 12px;
        }

        .money {
          color: #fd1011;
          padding-top: 50px;
          font-weight: bold;

          .money-num {
            font-size: 60px;
            display: inline-block;
          }
        }

        .type {
          background: linear-gradient(
            to right,
            rgba(250, 230, 230, 0.1),
            rgba(250, 231, 232, 1),
            rgba(250, 230, 230, 0.1)
          );
          font-size: 20px;
          color: #fd1011;
          width: 100%;
          padding: 4px 0;
          letter-spacing: 2px;
          position: relative;
        }

        .type::before {
          content: "";
          display: block;
          width: 4px;
          height: 4px;
          background: #fd1012;
          border-radius: 50%;
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
        }

        .type::after {
          content: "";
          display: block;
          width: 4px;
          height: 4px;

          background: #fd1012;
          border-radius: 50%;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }

        .btn {
          width: 100%;
          position: absolute;
          bottom: 18px;

          .btn-text {
            display: inline-flex;
            align-items: center;
            background: linear-gradient(to right, #f64136, #fd6e49);
            padding: 12px 36px;
            font-size: 24px;
            color: #ffffff;
            border-radius: 36px;

            .btn-icon {
              display: inline-block;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              color: #fd1011;
              margin-left: 20px;
              background: url(../../assets/arrow-right.png) center center
                no-repeat #ffffff;
              background-size: 8px;
            }
          }
        }
      }
    }
  }
}
</style>